import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  hero,
  heroText,
  menu,
  mobileHero,
  mobileHeroText,
  viewMenu,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95vh"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
        >
          <CFView mt="10px" column center w="100%" textCenter>
            <CFImage
              w="90%"
              src={mobileHeroText}
              alt="Genji hero text"
              zIndex={98}
            />
            <CFView pulsate mt="10px">
              <OrderPickupButton />
            </CFView>
            <CFView>
              <a href={menu}>
                <CFImage src={viewMenu} w="95%" maxWidth="320px" alt="About" />
              </a>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="90vh"
          maxHeight="690px"
          w="100%"
          image={`url(${hero}) 40% 0% / cover no-repeat`}
          zIndex={90}
          column
          justifyCenter
          alignStart
        >
          {/*<Header />*/}
          <CFView column justifyCenter alignStart ml="80px">
            <CFImage
              mt="-90px"
              w="550px"
              src={heroText}
              alt="Genji hero text"
              zIndex={98}
            />
            <CFView pulsate mt="10px">
              <OrderPickupButton />
            </CFView>
            <CFView>
              <a href={menu}>
                <CFImage src={viewMenu} w="100%" maxWidth="290px" alt="About" />
              </a>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
